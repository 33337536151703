import {Swiper, Navigation} from "swiper";

$(function () {
    new Swiper('.uq-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 20,
        speed: 500,
        grabCursor: true,
        breakpoints: {
            375: {
                slidesPerView: 1.2,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 1.8,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 2.2,
                spaceBetween: 20
            }
        }
    });
});