try {
    window.$ = window.jQuery = require('jquery');
    window.bootstrap = require('bootstrap/js/src/base-component');
    window.bootstrap = require('bootstrap/js/src/dropdown');
    window.bootstrap = require('bootstrap/js/src/tab');
    window.bootstrap = require('bootstrap/js/src/button');
    window.bootstrap = require('bootstrap/js/src/collapse');

    window.AOS = require('aos/dist/aos')

    require('@popperjs/core');

    require('./components/Menu');
    require('./components/Map');
    require('./components/AnimateNumbers');
    require('./components/Slider');
    require('./components/Category');
} catch (e) {
    console.error(e);
}

$(function () {
    AOS.init({
        startEvent: 'DOMContentLoaded',
        once: true,
        duration: 600,
        offset: 20,
        disable: window.matchMedia('(prefers-reduced-motion: reduce)').matches
    });

    let $sameHeightDivs = $('.swiper-wrapper .swiper-slide .card');

    function setSameHeight() {
        let minHeight = 0;

        $sameHeightDivs.each(function () {
            minHeight = Math.max(minHeight, $(this).outerHeight());
        });

        $sameHeightDivs.css({'min-height': minHeight + 'px'});
    }

    function resetSameHeight() {
        $sameHeightDivs.css({'min-height': 'unset'});
    }

    function setMainContentHeight() {
        let $main = $('main');
        let $header = $('#top');
        let $top_nav = $('#top-nav');
        let $footer = $('#footer');
        let $window_h = window.innerHeight;
        let $404_page = $('.uq-heading-404');

        if ($window_h > $main.outerHeight()) {
            $main.css('min-height', $window_h - $header.outerHeight() - $footer.outerHeight() - ($top_nav.outerHeight() ?? 0))
        }

        if ($404_page.length > 0) {
            $404_page.css('min-height', $window_h - $header.outerHeight() - $footer.outerHeight() - ($top_nav.outerHeight() ?? 0))
        }
    }

    resetSameHeight();
    setSameHeight();
    setMainContentHeight();

    $(window).on('load', function () {
        setTimeout(() => {
            AOS.refresh();
        }, 100);

        console.log("Document is ready!!");
    });

    $(window).on('resize', function (e) {
        setMainContentHeight();
        resetSameHeight();
        setSameHeight();
    })

    let $preloader = $('[data-preloader="load"]');

    if ($preloader.length > 0) {
        setTimeout((e) => {
            $('body').addClass('loaded');
            $preloader.addClass('loaded');
        }, 150);
    }

    console.log("%cMade with ♥ by Uniqoders - https://uniqoders.com", "color:#403c5e; font-size:36px")
})